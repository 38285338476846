import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import ConnectWithUs from '../components/sections/connect-with-us';
import HeroBanner from '../components/sections/hero-banner';
import Feature from '../components/sections/industry/feature-block';
import TripleFeature from '../components/sections/triple-feature';
import { getDemoLink } from '../services/demo-selector';
import { Route } from '../models/site-links';
import SEO from '../components/seo';

const AntiFraud = () => {
  const data = useStaticQuery(graphql`
    query {
      countertop: file(relativePath: { eq: "product-card/countertop-white.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fakeIDHero: file(relativePath: { eq: "hero-images/fakeID-hero-square.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fakePiBox: file(relativePath: { eq: "fake-id-scanner/fakepibox.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      security: file(relativePath: { eq: "icons/securityAlerts.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      challengeQuestions: file(relativePath: { eq: "icons/questions.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      suspicious: file(relativePath: { eq: "icons/suspicious.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fraudster: file(relativePath: { eq: "anti-fraud/Fraudster-latest.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      scannerGuts: file(relativePath: { eq: "fake-id-scanner/scanner-inside.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fakeIDAlert: file(relativePath: { eq: "entertainment/fakeID_alert.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      passports: file(relativePath: { eq: "fake-id-scanner/passportscan.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fakeIds: file(relativePath: { eq: "feature-images/fake-ids.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div>
      <SEO
        title="Anti-Fraud ID Scanners | Patronscan"
        description="Fight synthetic identity fraud with Patronscan’s ID scanners. Accurate fake ID detection. Avoid false positives. Identify known fraudsters on the shared flagged list."
      />
      <HeroBanner
        heroImage={data.fakeIDHero.childImageSharp.fluid}
        heading="Trusted ID Verification"
        subHeading="Catch fake IDs with North America's most reliable ID scanners."
        videoLink="https://player.vimeo.com/video/366101059?autoplay=1&loop=1&autopause=0&muted=1"
        showVideo
        linkText="Book Demo"
        link={getDemoLink()}
        buttonId="AntiFraudHero:BookDemo"
      />
      <Feature
        rightAlign
        image={data.fraudster.childImageSharp.fluid}
        heading="Fraudsters frequently"
        headingThin="use fake IDs"
        subHeading="Criminals often use high-quality fake IDs to commit identity fraud."
        description="The majority of ID scanners will not catch sophisticated fake IDs. Patronscan reliably catches fake IDs by performing advanced analysis on both the front and back of an ID."
        linkText="Contact Us"
        link={Route.TalkToAnExpert}
        buttonId="AntiFraudFeature:FraustersUseFakeIDs"
      />
      <Feature
        image={data.fakeIDAlert.childImageSharp.fluid}
        heading="Proven technology"
        headingThin="to catch fake IDs"
        subHeading="Patronscan scans over a million IDs and catches over 7,500 fake IDs every week."
        description="With over 15 years of experience catching fake IDs, Patronscan uses proprietary algorithms that look for slight discrepancies in fonts, spacing and layouts to determine an ID's authenticity."
        linkText="Book Demo"
        link={getDemoLink()}
        buttonId="AntiFraudFeature:ProvenTechnology"
      />
      <Feature
        rightAlign
        image={data.scannerGuts.childImageSharp.fluid}
        heading="Scans under dynamic"
        headingThin="lighting conditions"
        subHeading="Scans include sequences of infrared and white light to authenticate security features."
        description="Patronscan's PiBox dynamically activates lights when scanning to authenticate IDs. Advanced algorithms check for the appropriate responses and flag fraudulent features."
        linkText="Contact Us"
        link={Route.TalkToAnExpert}
        buttonId="AntiFraudFeature:ScansLightingConditions"
      />

      <Feature
        image={data.passports.childImageSharp.fluid}
        heading="Validate age and expiry"
        headingThin="from over 4,800 IDs"
        subHeading="Catch minors presenting fake IDs from just about anywhere. See alerts for expired and underaged IDs."
        description="Scan IDs from any North American government-issued ID including military IDs, status cards and passports. Includes support for verifying IDs issued from all over the world."
        linkText="Book Demo"
        link={getDemoLink()}
        buttonId="AntiFraudFeature:ValidateAgeExpiry"
      />
      <Feature
        rightAlign
        image={data.fakeIds.childImageSharp.fluid}
        heading="The world's largest"
        headingThin="library of fake IDs"
        subHeading="Patronscan matches scans against our extensive database of fake ID templates."
        description="Positive fake ID matching is just another way we catch more fake IDs than any other scanner. Known fake ID templates are continuously built into the system for even better accuracy."
        linkText="Get Pricing"
        link={Route.GetPricing}
        buttonId="AntiFraudFeature:WorldsLargestLibrary"
      />
      <TripleFeature
        firstFeatureIcon={data.security.childImageSharp.fluid}
        secondFeatureIcon={data.challengeQuestions.childImageSharp.fluid}
        thirdFeatureIcon={data.suspicious.childImageSharp.fluid}
        firstHeadingText="Security Notifications"
        secondHeadingText="Ask Challenge Questions"
        thirdHeadingText="Suspicious Visit Alerts"
        firstDescriptionText="Send SMS alerts directly to your security team every time a fake ID is caught or when customer interactions require intervention."
        secondDescriptionText="Not sure if an ID really belongs to a person? Ask a series of generated challenge questions based on the ID and gauge their reaction."
        thirdDescriptionText="Fraudsters often target multiple locations during a crime spree. Receive alerts for individuals that are making consecutive visits to your locations."
      />
      <ConnectWithUs
        buttonId="AntiFraudConnect"
        promptText="Speak to us today for pricing information or see how Patronscan protects against identity fraud."
      />
    </div>
  );
};

export default AntiFraud;
